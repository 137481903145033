import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { resourcesConfig } from '@proman/resources';
import { FilterService } from '@proman/services/filter.service';
import { Entity } from '@proman/services/entity.service';
import { ACL } from '@proman/services/acl.service';
import { contains, findById, unique } from '@proman/utils';
import { values as lodashValues } from '@proman/utils';
import { TableField } from '@proman/interfaces/object-interfaces';
import {
    Material,
    Order,
    Production,
    ProductionMaterial,
    ProductionOperation,
} from '@proman/interfaces/entity-interfaces';
import { ProductionEntityInterface } from '@proman/resources/production';
import { ParametersService } from '@proman/parameters/services/parameters.service';

@Injectable({ providedIn: 'root' })
export class ProductionsService {
    activePlanning: Subject<number|null> = new Subject<number|null>();
    operationsTimeStamp: Subject<number> = new Subject<number>();
    technologyUpdatedTimestamp: Subject<number> = new Subject<number>();
    productionEntity: ProductionEntityInterface;
    parameterEntity: any;

    constructor(
        private ACL: ACL,
        private Entity: Entity,
        private Filter: FilterService,
        private Parameters: ParametersService,
    ) {
        this.productionEntity = Entity.get('production') as ProductionEntityInterface;
        this.parameterEntity = Entity.get('parameter');
    }

    setPlanning(id: number) {
        this.activePlanning.next(id);
    }

    finishPlanning() {
        this.activePlanning.next(null);
    }

    refreshOperations() {
        this.operationsTimeStamp.next(new Date().getTime());
    }

    checkActions() {
        this.technologyUpdatedTimestamp.next(new Date().getTime());
    }

    async possibleActions(productionIds: number[]) {
        return this.productionEntity.possibleActions({ id: productionIds });
    }

    getProductionStatus = (order: Order) => {
        let icons = resourcesConfig.order.params.orderStatusIcons;
        let icon = icons[order.status];

        return {
            icon,
            text: this.Filter.translate(order.status)
        };
    };

    getLastOperationEndTime = (row: Production) => {
        let endTime = row.deadline;
        let operations = row.operations;

        if (operations && operations.length) endTime = operations[operations.length - 1].plannedEnd;

        return endTime;
    };

    getFields = (): TableField[] => {
        return [
            {
                name: '',
                key: 'color',
                filter: null,
                sortable: false,
                formatter: 'directive',
                formatterConfig: 'pro-entity-table-color'
            },
            {
                name: 'id',
                key: 'id'
            },
            {
                name: 'order_type',
                key: 'order.type.name',
                extraPartialJoins: {
                    'order.type': ['name'],
                }
            },
            {
                name: 'name',
                key: 'name',
            },
            {
                name: 'article',
                key: 'article.altName'
            },
            {
                name: 'manager',
                key: 'order.manager.name',
                extraPartialJoins: {
                    'order.manager': ['name'],
                }
            },
            {
                name: 'created_at',
                key: 'createdAt',
                formatter: 'dateTime',
                formatterConfig: '_datetime_js',
                showTime: true
            },
            {
                name: 'deadline',
                key: 'deadline',
                getValue: this.getLastOperationEndTime,
                formatter: 'dateTime'
            },
            {
                name: 'start_time',
                key: 'start',
                formatter: 'dateTime'
            },
            {
                name: 'order_status',
                key: 'status',
                getValue: this.getProductionStatus,
                formatter: 'compile',
                filter: {
                    type: 'dropdown_multi',
                    options: [
                        {
                            name: this.Filter.translate('created_order_status'),
                            id: this.productionEntity.CREATED
                        },
                        {
                            name: this.Filter.translate('confirmed_order_status'),
                            id: this.productionEntity.CONFIRMED
                        },
                        {
                            name: this.Filter.translate('started_order_status'),
                            id: this.productionEntity.STARTED
                        },
                        {
                            name: this.Filter.translate('complete_order_status'),
                            id: this.productionEntity.COMPLETE
                        },
                        {
                            name: this.Filter.translate('suspended'),
                            id: this.productionEntity.SUSPENDED,
                        },
                        {
                            name: this.Filter.translate('copied'),
                            id: this.productionEntity.COPIED,
                        }
                    ]
                }
            },
            {
                name: 'parameters',
                key: 'parameters',
                filter: {
                    type: 'search',
                    keys: ['parameters.parameter.name', 'parameters.parameter.alias', 'parameters.value'],
                },
                formatter: 'parameters',
                hidden: true,
                extraJoins: [
                    'parameters',
                    'parameters.parameter',
                    'parameters.children',
                    'parameters.children.parameter'
                ],
                preloadParametersData: true
            },
            {
                name: 'quantity',
                key: 'quantity',
                formatterConfig: 3,
                formatter: 'numeric',
                stats: ['sum']
            },
            {
                name: 'price',
                key: 'price',
                formatter: 'money',
                stats: ['sum'],
                acl: 'production.show_price'
            },
            {
                name: 'cost',
                key: 'cost',
                formatter: 'money',
                stats: ['sum'],
                acl: 'production.show_price'
            },
            {
                name: 'type',
                key: 'type.name',
                extraJoins: ['type'],
            },
            {
                name: 'subtype',
                key: 'subtype.name',
                extraJoins: ['subtype'],
            },
            {
                name: 'tags',
                key: 'tags',
                entityName: 'production',
                formatter: 'directive',
                formatterConfig: 'pro-tags',
                filter: {
                    type: 'autocomplete',
                    entity: 'tag'
                }
            }
        ];
    };

    getExtraParameters = resourcesConfig['production'].params.extraParameters;

    canPrint = (production: any) => {
        return new Promise((resolve) => resolve(production.status !== this.productionEntity.CREATED));
    };

    canConfirmTechnology = async (production: any, isOrderActive: any) => {
        let status = production.status;
        let result: boolean;

        if (!this.ACL.check('production.confirm') || !((status !== this.productionEntity.CREATED) || (status !== this.productionEntity.CONFIRMED)  ) || !isOrderActive) {
            result = false;
        } else if (status === this.productionEntity.CONFIRMED) {
            result = true;

        } else {
            await this.loadTechnology(production).then(() => {
                let l = production.ungroupedParameters.length;
                for (let i = 0; i < l; i++) {
                    if (this.Parameters.empty(production.ungroupedParameters[i])) {
                        result = false;
                        return;
                    }
                }

                for (let i in production.groups) {
                    let m = production.groups[i].parameters.length;
                    for (let j = 0; j < m; j++) {
                        let n = production.groups[i].parameters[j].children.length;
                        for (let k = 0; k < n; k++) {
                            if (this.Parameters.empty(production.groups[i].parameters[j].children[k])) {
                                result = false;
                                return;
                            }
                        }

                    }
                }
                result = true;
            });
        }

        return new Promise((resolve) => resolve(result));
    };

    canStart = (production: any, isOrderActive: boolean) => {
        return new Promise((resolve) => resolve(
            this.ACL.check('production.update_status') && isOrderActive &&
                (production.status === this.productionEntity.CONFIRMED ||
                    production.status === this.productionEntity.SUSPENDED ||
                    production.status === this.productionEntity.RESERVED))
        );
    };

    canReserve = (production: any, isOrderActive: any) => {
        return new Promise((resolve) => resolve(this.ACL.check('production.update_status') && (production.status === this.productionEntity.CONFIRMED) && isOrderActive));
    };

    canCancelReservation = (production: any) => {
        return new Promise((resolve) => resolve(this.ACL.check('production.update_status') && (production.status === this.productionEntity.RESERVED)));
    };

    canSuspend = (production: any) => {
        return new Promise((resolve) => resolve(this.ACL.check('production.update_status') && production.status === this.productionEntity.STARTED));
    };

    canRemove = () => {
        return new Promise((resolve) => resolve(this.ACL.check('production.remove')));
    };

    loadTechnology = async (production: any) => {
        let articlePromise;
        let technologyPromise;

        const getMaterialCategoriesInParameters = (materialCategories: any, productionParameters: any) => {
            let output: any = [];
            let l = productionParameters.length;
            let i;
            let productionParameter;

            for (i = 0; i < l; i++) {
                productionParameter = productionParameters[i];

                if (productionParameter.children && productionParameter.children.length > 0) {
                    output = output.concat(getMaterialCategoriesInParameters(materialCategories, productionParameter.children));
                }
                if (productionParameter.parameter.type === 'material_category') {
                    output.push(this.Parameters.getMaterialCategoryId(productionParameter));
                }
            }
            return unique(output);
        };

        const prepareArticleProductionParameters = (articleProductionParameters: any) => {
            return articleProductionParameters
                .filter((articleProductionParameter: any) => {
                    return articleProductionParameter.parameter.type === this.parameterEntity.PARAMETER_GROUP;
                });
        };

        const prepareGroupsMetadata = (metaParameters: any) => {
            let groups = {};
            let l = metaParameters.length;
            let metaParameter;
            let groupDefinitionId;

            for (let i = 0; i < l; i++) {
                metaParameter = metaParameters[i];
                groupDefinitionId = metaParameter.parameter.id;

                if (!groups[groupDefinitionId]) {
                    groups[groupDefinitionId] = { metaParameters: [], parameters: [] };
                }

                groups[groupDefinitionId].metaParameters.push(metaParameter);
            }

            return groups;
        };

        if (!production.$parametersInited) {
            // load article
            // get article groups//prepare meta
            let productionParameterEntity = this.Entity.get('production_parameter');

            articlePromise = this.productionEntity
                .get({
                    id: production.id,
                    join: ['article', 'article.files']
                })
                .then((data: any) => production.article = data.article);

            await articlePromise
                .then(async () => {
                    return await Promise.all([
                            this.getMaterials(production),
                            productionParameterEntity.QB.aggregate(production).search(),
                            this.Entity.get('article_production_parameter').search({
                                'article.id': production.article.id,
                                'join': ['parameter', 'children', 'children.parameter']
                            })
                        ]);
                    })
                    .then((values: any) => {
                        let materialCategories = values[0];
                        let productionParameters = values[1];
                        let articleProductionParameters = values[2];
                        let metaParameters = prepareArticleProductionParameters(articleProductionParameters);
                        let excludeMaterialCategories: any;
                        let filteredCategories;
                        let parameterCategories;

                        production.materialCategories = materialCategories;
                        production.productionParameters = productionParameters;
                        production.articleProductionParameters = articleProductionParameters;

                        production.groups = prepareGroupsMetadata(metaParameters);

                        // find all materialCategories, associated with parameters
                        excludeMaterialCategories = getMaterialCategoriesInParameters(materialCategories, productionParameters);

                        filteredCategories = materialCategories
                            .filter((materialCategory: any) => !contains(excludeMaterialCategories, materialCategory.id));

                        parameterCategories = materialCategories
                            .filter((materialCategory: any) => contains(excludeMaterialCategories, materialCategory.id));

                        production.filteredMaterialCategories = filteredCategories;
                        production.parameterCategories = parameterCategories;

                        try {
                            this.prepareParameters(production, productionParameters);
                        } catch (e) {
                            technologyPromise = null;
                            throw e;
                        }

                        production.$parametersInited = true;
                    });
        } else {

        }

        technologyPromise = new Promise((resolve: any) => resolve()).then((): any => technologyPromise = null);

        return technologyPromise;
    };

    getMaterials = async (production: any) => {
        return await Promise.all([
                this.Entity.get('material').search({
                    'articleMaterials.article.id': production.article.id,
                    'join': ['categories' /*, 'categories.rootParent' */]
                }),
                this.Entity.get('production_material').search({ 'production.id': production.id })
            ])
            .then((values: [Material[], ProductionMaterial[]]) => {
            let materials = values[0].map((material: any) => {
                material.enabled = !!findById(values[1], material);
                return material;
            });

            let materialCopy;

            // group materials by its categories' root parents
            const map = {};
            // for (const material of materials) {
            //     for (const category of material.categories) {
            //         let root = map[category.rootParent.id];
            //         if (!root) {
            //             root = category.rootParent;
            //             map[root.id] = root;
            //             root.materials = [];
            //         }
            //         materialCopy = {
            //             id: material.id,
            //             name: material.name,
            //             category: { id: category.id, name: category.name }, // avoid recusive structure to fix stack overflow
            //             rootCategory: { id: root.id, name: root.name },  // avoid recusive structure to fix stack overflow
            //             enabled: material.enabled
            //         };
            //         root.materials.push(materialCopy);
            //     }
            // }
            return lodashValues(map);
        });
    };

    prepareParameters = (production: any, productionParameters: any) => {
        // group all parameters into ungrouped (default, any type) and grouped(parameter_group type)
        // group all groups by group definition (productionParameter.parameterId)
        let ungroupedParameters = [];
        let groups = production.groups;

        for (let key in groups) {
            groups[key].parameters = [];

        }

        for (const productionParameter of productionParameters) {

            if (productionParameter.parameter.type !== this.parameterEntity.PARAMETER_GROUP) {
                ungroupedParameters.push(productionParameter);

                continue;

            }

            let groupData: any;

            try {
                groupData = JSON.parse(productionParameter.value);
            } catch (e) {
                groupData = {};
            }

            productionParameter.product = groupData?.product;
            productionParameter.name = groupData?.name;

            const groupDefinitionId = productionParameter.parameter.id;

            if (groups[groupDefinitionId]) {
                groups[groupDefinitionId].parameters.push(productionParameter);

            }
        }

        production.ungroupedParameters = ungroupedParameters;
    };

    getWithDependencies = (id: number) => {
        return this.productionEntity.get({ id, join: ['article'] })
            .then((production: any) => {
                let request: any = { id: production.id, join: [] };
                let query = this.productionEntity.QB;
                let promise;

                if (!production.productionProducts) {
                    query.joinProducts();
                }
                if (!production.article) {
                    request.join.push('article');
                }
                if (!production.completedArticleOperations) {
                    request.join.push('completedArticleOperations');
                    request.join.push('completedArticleOperations.operation');
                }

                if (!production.productionProducts || request.join.length > 0) {
                    promise = query.get(request).then((data: any) => {

                        if (!production.productionProducts) {
                            production.productionProducts = data.productionProducts;
                        }
                        if (!production.article) {
                            production.article = data.article;
                        }
                        if (!production.completedArticleOperations) {
                            production.completedArticleOperations = data.completedArticleOperations;
                        }

                        return new Promise((resolve) => resolve(production));
                    });
                } else {
                    return new Promise((resolve) => resolve(production));
                }

                return promise;
            });
    };

    finishProduction = (production: Production) => {
        return this.productionEntity.finish({id: production.id});
    };

    finishProductions = (productionsIds: any[]) => {
        return this.productionEntity.finishMultiple({ids: productionsIds});
    };

    confirmTechnology = (production: Production) => {
        return this.productionEntity.confirm({id: production.id});
    };

    confirmProductions = (productionsIds: any[]) => {
        return this.productionEntity.confirmMultiple({ids: productionsIds});
    };

    start = (production: Production | { id: number }) => {
        return this.productionEntity.start({id: production.id});
    };

    confirmAndStartProductions = (productionsIds: any[]) => {
        return this.productionEntity.startMultiple({ids: productionsIds});
    };

    suspend = (production: Production | { id: number }) => {
        return this.productionEntity.suspend({id: production.id});
    };

    sortOperations = (operations: ProductionOperation[]) => {
        const getStart = (operation: ProductionOperation) => operation.realStart || operation.plannedStart;

        operations.sort((a, b) => {
            // const aStart = getStart(a);
            // const bStart = getStart(b);
            const aStart = a.id;
            const bStart = b.id;

            return aStart < bStart ? -1 : 1;
        })
    };

}
